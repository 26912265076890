<template>
    <v-app :style="colorVapp">
        <onebox_admin_toolbar
        :quicksearch="true"
        ></onebox_admin_toolbar>
        <v-content>
            <v-card class="elevation-0">
                <!-- hearder -->
                <v-list nav :color="color.BG">
                <v-list-item class="text-left">
                    <v-list-item-avatar class="text-center">
                    <v-avatar :color="color.theme" size="35">
                        <v-icon dark size="20">mdi-image-edit</v-icon>
                    </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title>
                        <span :style="headerPage">&nbsp;{{ $t('logo_feature.manage_logo')}}</span>
                    </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list>
            </v-card>
            <v-card v-if="resolutionScreen >= 500" class="elevation-0" :color="color.BG" id="thiscontaineradmin">
                <v-card-text class="pa-0">
                    <v-container fluid>
                        <v-layout row wrap class="pl-6 pr-6">
                            <v-row>
                                <v-col md="12" lg="5" sm="5">
                                    <v-card class="pb-8 pt-5">
                                    <v-layout column align-center justify-center >
                                    <div class="text-center pointer noselect" v-if="demo_iconImage === default_logo" style="pointer-events: none;">
                                        <img
                                            width="200"
                                            height="150"
                                            style="object-fit: contain;"
                                            src="@/assets/logo_onebox-vertical-png.png"
                                        />
                                    </div>
                                    <div v-else class="text-center pointer noselect">
                                        <img
                                            width="200"
                                            height="150"
                                            style="object-fit: contain; pointer-events: none;"
                                            :src="demo_iconImage"
                                        />
                                    </div>
                                    <!-- canvasdrop-upload text-center -->
                                    <div :class="handlercanvasupload" :style="dropuploadimage">
                                        <v-layout
                                        column
                                        @click="ImportFiles"
                                        align-center
                                        justify-center
                                        class="inputuploadfiles"
                                        :style="dropzonestyle"
                                        v-if="imageObjectfile === null"
                                        >
                                        <v-icon size="60" class="textdropzone" color="#CFCFCF">
                                            mdi-image-move
                                        </v-icon>
                                        <span class="textdropzone noselect">
                                            {{ $t('logo_feature.dragupload') }}
                                        </span>
                                        <span class="textdropzone noselect">
                                            {{ $t('logo_feature.detail_upload') }}
                                        </span>
                                        <span class="textdropzone noselect" :style="messageAlert ? 'color: red !important;' : null">
                                            {{ $t('logo_feature.file_detail')  }}<span :style="messageAlert ? 'color: red !important;' : null" class="textdropzone font-weight-bold">{{ $t('logo_feature.file_detail_size')  }}</span> 
                                        </span>
                                        <input ref="importimg"
                                        class="d-none"
                                        type="file" name="myImage" 
                                        @change="fn_getImage" accept=".png, .jpg, .jpeg, .ico, .svg" />
                                        </v-layout>

                                        <v-layout
                                        column
                                        align-center
                                        justify-center
                                        class="inputeduploadfile"
                                        @click="ImportFiles"
                                        v-else
                                        >
                                        <div style="padding: 10px;">
                                            <v-icon size="60" color="#CFCFCF" >mdi-image</v-icon>
                                        </div>
                                        <div class="ml-4">
                                            <span class="text-body-1 font-weight-medium">
                                                {{ this.imageName }}
                                            </span>
                                        </div>
                                        <input ref="importimg"
                                        class="d-none"
                                        type="file" name="myImage" 
                                        @change="fn_getImage" accept=".png, .jpg, .jpeg, .ico, .svg" />
                                        </v-layout>
                                    </div>
                                    <div class="canvas-btn-action">
                                       <v-card outlined class="pt-4" style="border-color: transparent !important;">
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn  :disabled="demo_iconImage !== logo_default" 
                                            @click="fn_setDefault" :color="color.theme" :dark="demo_iconImage !== logo_default ? false : true">
                                                {{ $t('logo_feature.set_default') }}
                                            </v-btn>
                                            <v-btn   color="green" @click="fn_save_change"
                                             dark v-if="imageObjectfile !== null">
                                             {{ $t('logo_feature.save_change') }}
                                            </v-btn>
                                            <v-btn   color="error" v-if="imageObjectfile !== null" @click="clearImage">
                                                {{ $t('logo_feature.clear_') }}
                                            </v-btn>
                                        </v-card-actions>
                                       </v-card>
                                    </div>
                                    </v-layout>
                                    </v-card>
                                </v-col>
                                <v-col md="12" lg="7" sm="7">
                                    <v-card class="pb-15 cardManage" ref="cardExample">
                                    <v-layout column align-center justify-center>
                                    <v-list width="100%">
                                        <v-list-item>
                                            <v-chip> {{$t('default') === 'en' ? 'Example' : 'ตัวอย่างโลโก้' }} </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-switch
                                            v-model="switch1"
                                            :color="color.theme"
                                            :label="switch1 ? $t('logo_feature.dark_mode'): $t('logo_feature.light_mode')"
                                            inset
                                            ></v-switch>
                                            <!-- :label="" -->
                                            <!-- <v-icon>{{ switch1 ? 'mdi-moon-waxing-crescent mdi-rotate-135' : 'mdi-white-balance-sunny' }}</v-icon> -->
                                            <!-- <span  class="pl-3">
                                                {{ switch1 ? $t('logo_feature.dark_mode'): $t('logo_feature.light_mode') }}
                                            </span> -->
                                        </v-list-item>
                                    </v-list>
                                    <div class="demo-logo-web elevation-3" flat :style="drawerColor">
                                        <!-- ก่อนหน้า v-card เป็น div มาก่่อน -->
                                        <v-layout align-center column class="drawer-example pb-5" :style="drawerColor" >
                                            <img
                                            v-if="demo_iconImage === default_logo"
                                            width="60"
                                            height="40"
                                            style="object-fit: contain;"
                                            src="@/assets/logo_onebox-vertical-png.png"
                                            />
                                            <img
                                            v-else
                                            width="60"
                                            height="40"
                                            style="object-fit: contain;"
                                            :src="demo_iconImage"
                                            />
                                            <div v-for="(item, i) in test_data"
                                                :key="i" :style="boxDrawerColor" class="menu-example mt-3">
                                            
                                            </div>
                                            <v-divider></v-divider>

                                        </v-layout>
                                        <v-layout column class="nav-bar-example">
                                            <!-- NAV BAR EXAMPLE -->
                                            <div class="nav-bar-search"  :style="navBarSearch" >
                                                <v-icon size="18" class="ml-5" :color="colorIcon">
                                                    mdi-menu
                                                </v-icon>
                                                <!-- SEARCH EXAMPLE -->
                                                <div class="ml-6 search-bar" :style="searchColor">

                                                </div>
                                                <v-icon size="18" :color="colorIcon" class="ml-1">mdi-magnify </v-icon>
                                                <v-spacer></v-spacer>
                                                <!-- PROFILE EXAMPLE -->
                                                <v-divider inset vertical></v-divider>
                                                <div class="profile-box">
                                                    <v-avatar :color="colorAvarta" :size="sizeAvatar">
                                                    </v-avatar>
                                                    <div class="ml-2" :style="profileColor" ></div>
                                                </div>
                                            </div>
                                            <!-- CONTENT EXAMPLE -->
                                            <div class="content-example" :style="contentColor">
                                                <!-- HEADER -->
                                                <div class="header-content">
                                                    <v-avatar :color="color.theme" size="20" class="ml-5">
                                                        <v-icon size="12" dark>mdi-folder</v-icon>
                                                    </v-avatar>
                                                    <div class="ml-5">
                                                        <span class="text-button font-weight-bold" :style="colorText">{{$t('default') === 'en' ? 'Business' : 'บริษัท' }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div class="demo-logo-web mt-5 elevation-3" :style="drawerColor" >
                                        <v-layout align-center column class="drawer-example pb-5" :style="drawerColor_admin" >
                                            <img
                                            v-if="demo_iconImage === default_logo"
                                            width="60"
                                            height="40"
                                            style="object-fit: contain;"
                                            src="@/assets/logo_onebox-horizontal-white.png"
                                            />
                                            <img
                                            v-else
                                            width="60"
                                            height="40"
                                            style="object-fit: contain;"
                                            :src="demo_iconImage"
                                            />
                                            <div v-for="(item, i) in test_data"
                                                :key="i" :style="boxDrawerColor_admin" class="menu-example mt-3">
                                            
                                            </div>
                                            <v-divider></v-divider>

                                        </v-layout>
                                        <v-layout column class="nav-bar-example">
                                            <!-- NAV BAR EXAMPLE -->
                                            <div class="nav-bar-search"  :style="navBarSearch" >
                                                <v-icon size="18" class="ml-5" :color="colorIcon">
                                                    mdi-menu
                                                </v-icon>
                                                <v-spacer></v-spacer>
                                                <!-- PROFILE EXAMPLE -->
                                                <v-divider inset vertical></v-divider>
                                                <div class="profile-box">
                                                    <v-avatar :color="colorAvarta" :size="sizeAvatar">
                                                    </v-avatar>
                                                    <div class="ml-2" :style="profileColor" ></div>
                                                </div>
                                            </div>
                                            <!-- CONTENT EXAMPLE -->
                                            <div class="content-example" :style="contentColor">
                                                <!-- HEADER -->
                                                <div class="header-content">
                                                    <v-avatar :color="color.theme" size="20" class="ml-5">
                                                        <v-icon size="12" dark>mdi-image-edit</v-icon>
                                                    </v-avatar>
                                                    <div class="ml-5">
                                                        <span class="text-button font-weight-bold" :style="colorText">{{$t('default') === 'en' ? 'Manage logo' : 'จัดการโลโก้' }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-layout>
                                    </div>
                                    </v-layout>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-layout>
                    </v-container> 
                </v-card-text>
            </v-card>
            <v-card v-else class="elevation-0" :color="color.BG" id="thiscontaineradmin">
                <v-card-text class="pa-0">
                    <v-container fluid>
                        <v-layout row class="pl-6 pr-6">
                            <!-- <v-chip>{{ $vuetify.breakpoint.name }}</v-chip> -->
                            <!-- <v-row> -->
                                <v-flex sm12 xs12 md12>
                                    <v-card class="pb-8">
                                    <v-layout column align-center justify-center class="pt-5" >
                                    <div class="text-center pointer noselect" v-if="demo_iconImage === default_logo" style="pointer-events: none;">
                                        <img
                                            width="180"
                                            height="130"
                                            style="object-fit: contain;"
                                            src="@/assets/logo_onebox-vertical-png.png"
                                        />
                                    </div>
                                    <div v-else class="text-center pointer noselect">
                                        <img
                                            width="180"
                                            height="130"
                                            style="object-fit: contain; pointer-events: none;"
                                            :src="demo_iconImage"
                                        />
                                    </div>
                                    <!-- canvasdrop-upload text-center -->
                                    <div :class="handlercanvasupload" :style="dropuploadimage">
                                        <div v-if="imageObjectfile !== null && (['xs'].includes(this.$vuetify.breakpoint.name))" style="position: absolute;top: 2%; right: 2%;" >
                                            <v-btn fab depressed small @click="clearImage"  >
                                            <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                        </div>
                                        <v-layout
                                        column
                                        @click="ImportFiles"
                                        align-center
                                        justify-center
                                        class="inputuploadfiles"
                                        :style="dropzonestyle"
                                        v-if="imageObjectfile === null"
                                        >
                                        <v-icon size="45" class="textdropzone" color="#CFCFCF">
                                            mdi-image-move
                                        </v-icon>
                                        <span class="textdropzone noselect">
                                            {{ $t('logo_feature.dragupload') }}
                                        </span>
                                        <span class="textdropzone noselect">
                                            {{ $t('logo_feature.detail_upload') }}
                                        </span>
                                        <span class="textdropzone noselect" :style="messageAlert ? 'color: red !important;' : null">
                                            {{ $t('logo_feature.file_detail')  }} <span :style="messageAlert ? 'color: red !important;' : null" class="textdropzone font-weight-bold">{{ $t('logo_feature.file_detail_size')  }} </span> 
                                        </span>
                                        <input ref="importimg"
                                        class="d-none"
                                        type="file" name="myImage" 
                                        @change="fn_getImage" accept=".png, .jpg, .jpeg, .ico, .svg" />
                                        </v-layout>
                                        
                                        <v-layout
                                        column
                                        align-center
                                        justify-center
                                        class="inputeduploadfile"
                                        @click="ImportFiles"
                                        v-else
                                        >
                                        <div style="padding: 10px;">
                                            <v-icon size="60" color="#CFCFCF" >mdi-image</v-icon>
                                        </div>
                                        <div class="ml-4">
                                            <span class="text-body-1 font-weight-medium">
                                                {{ this.imageName }}
                                            </span>
                                        </div>
                                        
                                        
                                        <input ref="importimg"
                                        class="d-none"
                                        type="file" name="myImage" 
                                        @change="fn_getImage" accept=".png, .jpg, .jpeg, .ico, .svg" />
                                        </v-layout>
                                    </div>
                                    <div class="canvas-btn-action">
                                        <v-layout row wrap align-center justify-center class="pt-4 px-3">
                                            <!-- <v-spacer></v-spacer> -->
                                            <v-btn width="120"  small :disabled="demo_iconImage !== logo_default" class="ml-4 mt-3"
                                            @click="fn_setDefault" :color="color.theme" :dark="demo_iconImage !== logo_default ? false : true">
                                            {{ $t('logo_feature.set_default') }}
                                            </v-btn>
                                            <v-btn width="110" small color="green" @click="fn_save_change" class="ml-4 mt-3"
                                             dark v-if="imageObjectfile !== null">
                                             {{ $t('logo_feature.save_change') }}
                                            </v-btn>
                                            <v-btn width="80" small color="error" v-if="imageObjectfile !== null && (['sm', 'md'].includes(this.$vuetify.breakpoint.name)) " @click="clearImage" class="ml-4 mt-3">
                                                {{ $t('logo_feature.clear_') }}
                                            </v-btn>
                                        </v-layout>
                                       <!-- <v-card outlined class="pt-4" color="red" style="border-color: transparent !important;">
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn width="120"  small :disabled="demo_iconImage !== logo_default" 
                                            @click="fn_setDefault" :color="color.theme" :dark="demo_iconImage !== logo_default ? false : true">
                                                Set as default
                                            </v-btn>
                                            <v-btn width="110" small color="green" @click="fn_save_change"
                                             dark v-if="imageObjectfile !== null">
                                                Save Change
                                            </v-btn>
                                            <v-btn width="80" small color="error" v-if="imageObjectfile !== null" @click="clearImage">
                                                Clear
                                            </v-btn>
                                        </v-card-actions>
                                       </v-card> -->
                                    </div>
                                    </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 xs12 md12>
                                    <v-card class="mt-6 pb-15">
                                        <v-layout column>
                                        <v-list width="100%">
                                        <v-list-item>
                                            <v-chip small> {{$t('default') === 'en' ? 'Example' : 'ตัวอย่างโลโก้' }} </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-switch
                                            dense
                                            v-model="switch1"
                                            :color="color.theme"
                                            inset
                                            ></v-switch>
                                            <v-icon>
                                               {{ switch1 ? 'mdi-weather-night' : 'mdi-white-balance-sunny' }}
                                            </v-icon>
                                        </v-list-item>
                                        </v-list>
                                        </v-layout>
                                        <v-layout row wrap align-center justify-center class="pl-8 pr-8 pb-4">
                                            <v-flex xs12 sm5>
                                                <div class="demo-logo-mobile elevation-3" style="background-color: #C0C0C0; border-radius: 10px; border: 1px solid #a1a1a1;">
                                                    <v-layout align-center column class="drawer-example-mobile pb-5" :style="drawerColor" >
                                                    <img
                                                    v-if="demo_iconImage === default_logo"
                                                    width="70"
                                                    height="50"
                                                    style="object-fit: contain;"
                                                    src="@/assets/logo_onebox-vertical-png.png"
                                                    />
                                                    <img
                                                    v-else
                                                    width="70"
                                                    height="50"
                                                    style="object-fit: contain;"
                                                    :src="demo_iconImage"
                                                    />
                                                    <div v-for="(item, i) in test_data"
                                                        :key="i" :style="boxDrawerColor_admin" class="menu-example mt-3">
                                                    
                                                    </div>
                                                    <v-divider></v-divider>
                                                    </v-layout>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 sm5>
                                                <div class="demo-logo-mobile elevation-3 mt-5 mt-sm-0 ml-sm-4" style="background-color: #C0C0C0; border: 1px solid #a1a1a1; border-radius: 10px;">
                                                    <v-layout align-center column class="drawer-example-mobile pb-5" :style="drawerColor_admin" >
                                                    <img
                                                    v-if="demo_iconImage === default_logo"
                                                    width="70"
                                                    height="50"
                                                    style="object-fit: contain;"
                                                    src="@/assets/logo_onebox-horizontal-white.png"
                                                    />
                                                    <img
                                                    v-else
                                                    width="70"
                                                    height="50"
                                                    style="object-fit: contain;"
                                                    :src="demo_iconImage"
                                                    />
                                                    <div v-for="(item, i) in test_data"
                                                        :key="i" :style="boxDrawerColor_admin" class="menu-example mt-3">
                                                    
                                                    </div>
                                                    <v-divider></v-divider>
                                                    </v-layout>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            <!-- </v-row> -->
                        </v-layout>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-content>
        <div v-if="isConsenttoChange">
            <consent_logo 
                :show="isConsenttoChange"
                @cancelChnage="fn_cancel"
                @closeAccept="fn_upload_logo"
            />
        </div>
    </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
const consent_logo = () => import("../components/optional/dialog-consent-logo.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
    components: {
        onebox_admin_toolbar,
        onebox_toolbar,
        consent_logo
    },
    data: function (){  
        return{
            finalBaseImage: "",
            base64String: "",
            isConsenttoChange: {show: false, status: '', payload: ''},
            messageAlert: null,
            isAnimShaking: false,
            imageObjectfile: null,
            imageName: "",
            imageSize: "",
            imageType: "",
            default_logo: "@/assets/logo_onebox-vertical-png.png",
            demo_iconImage: "",
            switch1: false,
            drawer: null,
            isOpenOverlay: false,
            disablePointed: false,
            test_data: Array(6),
            example_business: [0,1,2,3,4,5]
        }
    },
    watch:{
    '$route.params.name': {
        handler: function(search) {
        console.log("SEARCH ", search)
        },
        deep: true,
        immediate: true
    }
    },
    methods: {
        imageUploaded() {
            let file = document.querySelector(
                'input[type=file]')['files'][0];
 
            let reader = new FileReader();
            console.log("next");
 
            reader.onload = function () {
                base64String = reader.result.replace("data:", "")
                    .replace(/^.+,/, "");
 
                imageBase64Stringsep = base64String;
 
                // alert(imageBase64Stringsep);
                this.testBaseImg = base64String
                console.log(base64String);
            }
            reader.readAsDataURL(file);
        },
        convertJpgToPng (jpgUrl, callback) {
            try {
            console.log("DOWN HERE ", jpgUrl)
            var img = new Image();
            img.onload = function() {
            var canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
        
            // Convert the image to PNG format
            var pngDataUrl = canvas.toDataURL('image/png');
            
            // Pass the converted PNG URL to the callback function
            callback(pngDataUrl);
            };
            img.src = jpgUrl;       
            } catch(er) {
                console.log("error ",er)
            }
        },
        fn_covertImagetoBase (image, callback) {
            // TEST BASE 64
            let reader = new FileReader();
                reader.onload = function () {
                this.base64String = reader.result.replace("data:", "")
                    .replace(/^.+,/, "");
                // this.testBaseImg = `data:image/${image.type.split('/')[1]};base64,`+this.base64String
                callback(`data:image/${image.type.split('/')[1]};base64,`+this.base64String)    
                
                }
                reader.readAsDataURL(image);
        },
        async fn_getImage(e) {
            console.log("file name ",e)
            if(this.checkAlertFile(e.target.files)){
                this.removeEventUploadImage()
                setTimeout(() => {
                    this.addEventUploadfiles()
                    this.isAnimShaking = false
                }, 1500)
                return
            } else {
                this.imageObjectfile = e.target.files[0]
                const file = e.target.files[0];
                console.log("IMAGE FILE ", this.imageObjectfile)
                this.demo_iconImage = URL.createObjectURL(file);
                this.imageName = e.target.files[0].name
                // CONVERT IMAGE TO BASE 64
                this.fn_covertImagetoBase(e.target.files[0], (resultBase)=>{
                    this.finalBaseImage = resultBase
                })
                this.removeEventUploadImage()
            }
            
        },
        ImportFiles() {
        //    this.fileImport = null;
           this.$refs.importimg.value = null;
           window.addEventListener(
           "focus",
           () => {
               this.selectingImportFiles = false;
           },
           { once: true }
           );
           this.$refs.importimg.click();
        },
        fn_setDefault () {
            if(this.imageObjectfile !== null){
                this.isConsenttoChange = {
                    show: true,
                    status: "SD",
                    payload: ""
                }
                //this.clearImage()
            } else {
                // ต้องเช็ค condontion จาก state ของ vuex ว่ารุปภาพนี้เป็น default (@) อยู่แล้วหรือเปล่า
                if(this.logo_default === "" || this.logo_default === 'default'){
                    Toast.fire({
                        icon: "success",
                        title: ''
                    });
                } else {
                    let payload_image = {
                        account_admin: this.dataAccountId,
                        business_id: this.dataAccountActive.business_info.business_id,
                        image_logo: ""
                    }
                    this.isConsenttoChange = {
                        show: true,
                        status: "SD",
                        payload: payload_image
                    }
                }
                //  SHOW dialog consent set logo to dedault as ONEBOX
                // ต้องมีการเช็คก่อนว่า logo เป็น default อยู่แล้วหรือไม่อย่างไร 
            }
        },
        fn_save_change () {
            //VUE_APP_SERVICE_ADMIN_BOX
            let payload_image = {
                account_admin: this.dataAccountId,
                business_id: this.dataAccountActive.business_info.business_id,
                image_logo: this.finalBaseImage
            }
            this.isConsenttoChange = {
                show: true,
                status: "SC",
                payload: payload_image
            }
        },
        fn_upload_logo (status_acc) {
            console.log("Status Accept ", status_acc)
            if(status_acc.status_accept === 'SD'){
                this.isConsenttoChange = {
                    show: false,
                    status: '',
                    payload: ''
                }
                this.clearImage()
            } else if (status_acc.status_accept === 'SC') { // SAVE CHANGE
                this.isConsenttoChange = {
                    show: false,
                    status: '',
                    payload: ''
                }
                this.clearImage()
            } else {
                Toast.fire({
                    icon: "error",
                    title: this.$t('toast.cannotconnectonebox')
                });
                this.isConsenttoChange = {
                    show: false,
                    status: '',
                    payload: ''
                }
                return
            }
            setTimeout(() => {
                window.location.reload()
            }, 500)
        },
        fn_cancel () {
            this.isConsenttoChange = {
                show: false,
                status: "SD",
                payload: ""
            }
        },
        clearImage () {
            this.messageAlert = null
            this.imageObjectfile = null
            this.imageName =  ""
            this.imageSize = ""
            this.imageType = ""
            this.demo_iconImage = this.logo_default === '' || this.logo_default === 'default' ? this.default_logo : this.logo_default
            this.addEventUploadfiles()
        },
         dragenter (e) {
            e.preventDefault();
            if(e.target.classList.contains('inputuploadfiles') || 
                e.target.classList.contains('textdropzone') ){
                this.disablePointed = true
                this.isOpenOverlay = true
            } else {
                this.isOpenOverlay = false
            }
         },
         
         dragleave (e) {
            e.preventDefault();
            try {
                if(e.fromElement.classList.contains('inputuploadfiles')){
                    return
                } else if (
                    e.fromElement.classList.contains('canvasdrop-upload') ||
                    e.fromElement === null) {
                    this.disablePointed = false
                    this.isOpenOverlay = false
                }
            }
            catch (err) {
                this.disablePointed = false
                this.isOpenOverlay = false
            }
         },
         dragover (e) {
            e.preventDefault();
            if(e.target.classList.contains('inputuploadfiles') || 
                e.target.classList.contains('textdropzone') ){
                this.disablePointed = true
                this.isOpenOverlay = true
            } else {
                this.disablePointed = false
            }
         },
         dropfile (e) {
            e.preventDefault();
            e.stopPropagation();
            if(!this.disablePointed){
                return
            }
            this.isOpenOverlay = false
            console.log("WHAT IS YOU DROPED ", e.dataTransfer)
            var dt = e.dataTransfer;
            var files = dt.files;
            // Check quatity
            if(this.checkAlertFile(files)){
                this.removeEventUploadImage()
                setTimeout(() => {
                    this.addEventUploadfiles()
                    this.isAnimShaking = false
                }, 1500)
                return
            } else {
                this.imageObjectfile = files[0]
                const file = files[0];
                console.log("FILLE IMAGE ", file)
                this.demo_iconImage = URL.createObjectURL(file);
                this.imageName = files[0].name
                // CONVERT IMAGE TO BASE 64
                this.fn_covertImagetoBase(files[0], (resultBase)=>{
                    this.finalBaseImage = resultBase
                })
                this.removeEventUploadImage()
            }
            
            
         },

         checkAlertFile (files) {
            if(files.length > 1){
                console.log("More than one file")
                this.messageAlert = true
                this.isAnimShaking = true
                return true
            } else {
                console.log("FILEE IMAGE ", files)
                // Check file type
                if(files[0].type.split('/')[0] !== 'image' ||
                    !['jpg', 'png', 'jpeg', 'svg+xml', 'x-icon'].includes(files[0].type.split('/')[1])){
                    this.messageAlert =  true
                    this.isAnimShaking = true
                    console.log("File is not type ", files[0].type)
                    return  true 
                }

                // เช็คกำหนดขนาด size ของ logo 
                if (files[0].size > 4000000) {
                    this.messageAlert =  true
                    this.isAnimShaking = true
                    console.log("File size not ", files[0].size)
                    return true 
                }

                return false
            }
         },

        removeEventUploadImage() {
        var self = this;
        console.log("removeEventUploadfiles");
        self.closeDragDrop = true;
        window.removeEventListener("dragenter", self.dragenter, true);
        window.removeEventListener("dragleave", self.dragleave, true);
        window.removeEventListener("dragover", self.dragover, true);
        window.removeEventListener("drop", self.dropfile, true);
        },
        addEventUploadfiles() {
        console.log("addEventUploadfiles");
            // ######### เช็คว่าหน้าจอเล็กไม่ให้ drag drop ได้ #########
            var self = this;
            self.closeDragDrop = false;
            window.addEventListener("dragenter", self.dragenter, true);
            window.addEventListener("dragleave", self.dragleave, true);
            window.addEventListener("dragover", self.dragover, true);
            window.addEventListener("drop", self.dropfile, true);
        },

    },
    computed: {
        ...mapState(["username", "authorize", "account_active", "service", "color", "logo_default"]),
        ...mapState({ processloader: "loading" }),
        ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
        "dataStorageUsage",
        "dataStorageMax",
        ]),
        resolutionScreen() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return 220;
            case "sm":
            return 400;
            case "md":
            return 500;
            case "lg":
            return 600;
            case "xl":
            return 800;
        }
        },
        colorVapp() {
            return "background:" + this.color.BG + ";";
        },
        headerPage() {
            return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
        },
        widthResendBtn() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
            return "40%";
            default:
            return "35%";
        }
        },
        navBarSearch () {
            return `backgroundColor: ${this.switch1 === true ? '#212121' :'white'}`
        },
        drawerColor () {
            return `backgroundColor: ${this.switch1 === true ? '#363636' :'white'}`
        },
        drawerColor_admin () {
            return `backgroundColor: ${this.color.theme}`
        },
        contentColor () {
            return `backgroundColor: ${this.switch1 === true ? '#333333' :'white'}`
        },
        boxDrawerColor () { 
            return `width: 90%; height: 8px; backgroundColor: ${this.switch1 === true ? '#454545' :'#f0f0f0'}`
        },
        boxDrawerColor_admin () { 
            return `width: 90%; height: 8px; backgroundColor: ${'#f0f0f0'}`
        },
        boxBtnDrawerColor () { 
            return `width: 90%; height: 4%; borderRadius: 8px; backgroundColor: ${this.switch1 === true ? '#454545' : '#f0f0f0'}`
        },
        boxBtnDrawerColor_admin () { 
            return `width: 90%; height: 6%; borderRadius: 8px; backgroundColor: #f0f0f0;`
        },
        boxMenuDrawerColor () { 
            return `width: 90%; height: 4px; borderRadius: 8px; backgroundColor: ${this.switch1 === true ? '#454545' : '#f0f0f0'}`
        },
        boxMenuDrawerColor_admin () { 
            return `width: 90%; height: 4%; borderRadius: 8px; backgroundColor: '#f0f0f0'`
        },
        profileColor () { 
            return `width: 60%; height: 20%; backgroundColor: ${this.switch1 === true ? '#454545' : '#f0f0f0'}`
        },
        colorIcon () {
            return `${this.switch1 === true ? '#454545' : '#fffff' }`
        },
        colorAvarta () {
            return `${this.switch1 === true ? '#454545' : '#f0f0f0' }`
        },
        searchColor () {
            return `backgroundColor: ${this.switch1 === true ? '#454545' : '#f0f0f0' }`
        },
        colorText () {
            return `color: ${this.switch1 === true ? 'white' : '#454545' }`
        },
        sizeAvatar () {
            return ['sm', 'md', 'lg'].includes(this.$vuetify.breakpoint.name) ? 18 : 16
        },
        dropzonestyle () {
            if(this.isOpenOverlay){
                return 
            } else {
                return 
            }
            
        },
        dropuploadimage () {
            return (
            "border: 3px dashed " +
            this.color.theme +
            `; backgroundColor: ${this.isOpenOverlay ? this.color.theme : this.color.BG}`+ 
            "; transition: background 200ms, opacity 200ms, height 175ms, width 175ms, border 300ms; " + 
            "borderRadius: 10px;"
        );
        },
        disablePointedText () {
            return `pointerEvents: none;`
        },
        handlercanvasupload (){
            if(this.isAnimShaking === true){
                return `canvasdrop-upload text-center horizontal-shake py-6 px-3`
            } else {
                return `canvasdrop-upload text-center py-6 px-3`
            }
        }
    },
    mounted () {
        console.log("ASASAS ", this.logo_default)
        console.log("Default ", this.default_logo)
        this.switch1 = this.$vuetify.theme.dark
        this.demo_iconImage = this.logo_default === 'default' || this.logo_default === ''  ? this.default_logo : this.logo_default
        this.addEventUploadfiles();
    },
    beforeDestroy(next) {   
        this.removeEventUploadImage();
    },
    
    
}
</script>

<style scrope>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.inputuploadfiles {
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.inputeduploadfile {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#thiscontaineradmin {
  height: calc(101vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.box-manage {
    padding-top: 10px;
    height: calc(101vh - 300px);
    width: 100%;
}
.box-example {
    height: calc(101vh - 300px);
    width: 100%;
    /* background-color: #a2a2a2; */
}
.logo-circle-canvas {
    position: relative;
    width: 30%;
    height: 30%;
    border-radius: 50%;
    background-color: lightblue;
}
.canvasdrop-upload {
    width: 90%;
    margin-top: 20px;
}
.canvas-btn-action {
    width: 90%;
}
.demo-logo-web {
    width: 50%;
    /* height: 60%; */
    /* height: calc(101vh - 600px); */
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1; 
    border-radius: 10px;
}
.drawer-example {
    width: 20%;
    height: 250px;
    padding-top: 20px;
    box-shadow: 4px 0 4px -4px #b2b2b2;
    z-index: 3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.nav-bar-example {
    width: 70%;
    height: 100%;
    border-top-right-radius: 10px;
}
.menu-example {
    width: 10%;
    height: 7%;
    border-radius: 8px;
    background-color: #f0f0f0;
}
.content-example {
    width: 100%; 
    height: 85%; 
    background-color: white; 
    z-index: 1;
    border-bottom-right-radius: 10px;
}
.header-content {
    width: 100%;
    height: 15%;
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.body-content {
    width: 100%;
    height: 85%;
    background-color: yellow;
}
.cardfolder-item {
    width: 12%; 
    height: 80px;
    border-radius: 8px;
    background-color: blue
}
.nav-bar-search{
    width: 100%; 
    height: 15%; 
    background-color: white; 
    z-index: 2;
    /* box-shadow: 5px 0 5px -5px #333;  */
    box-shadow: 0 5px 5px -5px #b2b2b2; 
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-right-radius: 10px;
}
.search-bar {
    width: 35%; 
    height: 10px; 
    background-color: #f0f0f0;
    border-radius: 4px;
}
.profile-box {
    width: 25%;
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.profile-circle {
    width: 90%;
    height: 30px;
    border-radius: 15%;
    background-color: #f0f0f0;
}
.div.btn__content {
  width: 90%;
}



/* Mobile */


.drawer-example-mobile {
    width: 60%;
    height: 100%;
    padding-top: 20px;
    box-shadow: 4px 0 4px -4px #b2b2b2;
    z-index: 3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* XS */
@media only screen  and (min-width: 200px) and (max-width: 400px) {
    
}


@media only screen  and (min-width: 200px) and (max-width: 400px) 
and (min-height: 650px ) {
    
}

@media only screen  and (min-width: 200px) and (max-width: 600px) 
and (min-height: 700px ) {
    
}

/* SM (IPAD, TAB) */
 @media only screen  and (min-width: 600px) and (max-width: 1200px) 
 and (min-height: 1000px) {
    
}
/* @media only screen  and (min-width: 600px) and (max-width: 700px) {
    .demo-logo-mobile {
    height: calc(101vh - 650px);
    }
}
@media only screen  and (min-width: 700px) and (max-width: 900px) {
    .demo-logo-mobile {
    height: calc(101vh - 850px);
    }
} */

/* .demo-logo-mobile {
    height: calc(101vh - 200px);
} */

/* @media only screen  and (min-width: 600px) 
and (max-width: 900px)
and (max-height: 500px) {
    .demo-logo-mobile {
    height: calc(101vh - 100px);
    background-color: red;
    }
    
}

@media only screen  and (min-width: 600px) 
and (max-width: 900px)
and (min-height: 100px) and (max-height: 1400px) {
    .demo-logo-mobile {
    height: calc(101vh - 800px);
    background-color: red;
    }
    
} */

/* @media only screen  and (min-width: 300px) and (max-width: 500px) {
    .box-manage {
    height: calc(101vh - 450px);
    width: 100%;
    } 
    
} */


/* onlyfor ipad pro protait */
/* @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1700px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .box-manage {
    height: calc(101vh - 800px);
    width: 100%;
    }
    .box-example {
        height: calc(101vh - 600px);
        width: 100%;
    }
    .demo-logo-web {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1;
    }
    
    .canvasdrop-upload {
    width: 80%;
    height: 25%;
    margin-top: 20px;
    }
    .canvas-btn-action {
        width: 80%;
    }
} */

/* ipad pro Landscape */
/* @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1700px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .box-manage {
    padding-top: 10px;
    height: calc(101vh - 360px);
    width: 100%;
    }
    .box-example {
        height: calc(101vh - 360px);
        width: 100%;
    }

} */


/* @media only screen  and (min-width: 1800px) {
    .drawer-example {
    width: 15%;
    height: 100%;
    padding-top: 20px;
    box-shadow: 4px 0 4px -4px #b2b2b2;
    z-index: 3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    }   
    
} */



/* md to lg */
/* @media only screen and (min-width: 1023px) and  (max-width: 1700px) {
    .demo-logo-web {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1;
    }
}

@media only screen and (min-width: 1023px) and  (max-width: 1700px) {
    .demo-logo-web {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1;
    }
}

@media only screen and (min-width: 1700px) and  (max-width: 1799px) {
    .demo-logo-web {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1;
    }
}

@media only screen  and (min-width: 1800px) {
    .demo-logo-web {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: row;
    border: 1px solid #a1a1a1;
    }
    
} */

.horizontal-shake {
  animation: horizontal-shaking 0.35s;
}

@keyframes horizontal-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
}


</style>